import React, {Fragment, FunctionComponent, useEffect, useMemo, useState} from 'react';
import YouTube, {YouTubeEvent} from "react-youtube";
import {useSearchParams} from "react-router-dom";

type Log = { id: number, value: string, date: Date }

const ON_PLAYER_READY_EVENT: string = 'Ready to start'

export const useDocumentVisible = (documentElement = document) => {
    const [documentVisible, setDocumentVisible] = useState(
        documentElement.visibilityState,
    );

    useEffect(() => {
        const handleVisibilityChange = () => setDocumentVisible(documentElement.visibilityState)

        documentElement.addEventListener(
            "visibilitychange",
            handleVisibilityChange,
        );

        return () =>
            documentElement.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
    }, [documentElement]);

    return documentVisible === "visible";
};

// const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const apiRecordEvent = async (eventType: number, videoId: string | null, userId: string | null, event: YouTubeEvent) => {
    return await fetch("https://gpa-tracker-api.sysei.mx/v1/youtube-events/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "event_type": eventType,
            "video_id": videoId,
            "user_id": userId,
            "playback_quality": event.target.getPlaybackQuality(),
            "playback_rate": event.target.getPlaybackRate(),
            "error_code": null
        })
    })
}

const apiRecordTimeWatched = async (videoId: string | null, userId: string | null, hours: number, minutes: number, seconds: number, milliseconds: number) => {
    return await fetch("https://gpa-tracker-api.sysei.mx/v1/youtube-time-watched/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "video_id": videoId,
            "user_id": userId,
            "hours": hours,
            "minutes": minutes,
            "seconds": seconds,
            "milliseconds": milliseconds,
        })
    })
}


const VideoTrackingPlayer: FunctionComponent<{
    debugMode?: boolean,
}> = ({debugMode}) => {
    const [searchParams] = useSearchParams();

    const [isReady, setIsReady] = useState(false);

    const [time, setTime] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const [logs, setLogs] = useState<Log[]>([])

    // const [documentVisible, setDocumentVisible] = useState(
    //     document.visibilityState,
    // );

    const {hours, minutes, seconds, milliseconds} = useMemo(() => ({
        hours: Math.floor(time / 360000),
        minutes: Math.floor((time % 360000) / 6000),
        seconds: Math.floor((time % 6000) / 100),
        milliseconds: time % 100,
    }), [time])

    // useEffect(() => {
    //     const unloadCallback = (event: any) => {
    //         event.preventDefault()
    //         event.returnValue = ""
    //         return ""
    //     };
    //
    //     window.addEventListener("beforeunload", unloadCallback)
    //     return () => window.removeEventListener("beforeunload", unloadCallback)
    // }, [])

    const videoId = useMemo(() => searchParams.get("id"), [searchParams])
    const userId = useMemo(() => searchParams.get("user-id"), [searchParams])

    useEffect(() => {
        const handleVisibilityChange = () => setLogs(prevState => ([
            ...prevState,
            {id: prevState.length + 1, value: `Tab visibility: ${document.visibilityState}`, date: new Date()}
        ]))//setDocumentVisible(document.visibilityState)

        document.addEventListener(
            "visibilitychange",
            handleVisibilityChange,
        );

        return () =>
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
    }, []);

    useEffect(() => {
        let intervalId: NodeJS.Timer | undefined
        if (isRunning && videoId && userId) {
            intervalId = setInterval(async () => {
                setTime(time + 1)
                await apiRecordTimeWatched(videoId, userId, hours, minutes, seconds, milliseconds)
            }, 8)
        }
        return () => clearInterval(intervalId)
    }, [hours, isRunning, milliseconds, minutes, seconds, time, userId, videoId]);

    if (videoId === null) return <>Loading videoId...</>

    const onReadyCallback = async (event: YouTubeEvent) => {
        if (!isReady) {
            try {
                await apiRecordEvent(-2, videoId, userId, event)

                setLogs((prevState) => {
                    if (!prevState.find(log => log.value === ON_PLAYER_READY_EVENT)) {
                        return [...prevState, {
                            id: prevState.length + 1,
                            value: ON_PLAYER_READY_EVENT,
                            date: new Date()
                        }]
                    }
                    return prevState
                })
                setIsReady(true)
            } catch (e) {
                console.error(e)
                setIsReady(false)
            }
        }
    }

    return (
        <>
            <div className="video-container">
                <YouTube
                    videoId={videoId}
                    opts={{
                        height: '100%',
                        width: '100%',
                        playerVars: {
                            autoplay: 0,
                        },
                    }}
                    onReady={onReadyCallback}
                    onPlay={async (event) => {
                        await apiRecordEvent(1, videoId, userId, event)

                        setLogs(prevState => (
                            [...prevState, {
                                id: prevState.length + 1,
                                value: "Play",
                                date: new Date()
                            }]
                        ))
                        setIsRunning(true)
                    }}
                    onEnd={async (event) => {
                        await apiRecordEvent(0, videoId, userId, event)

                        setLogs(prevState => (
                            [...prevState, {id: prevState.length + 1, value: "Completed", date: new Date()}]
                        ))
                        setIsRunning(false)
                    }}
                    onPause={async (event) => {
                        await apiRecordEvent(2, videoId, userId, event)

                        setLogs(prevState => (
                            [...prevState, {
                                id: prevState.length + 1,
                                value: "Pause",
                                date: new Date()
                            }]
                        ))
                        setIsRunning(false)
                    }}
                    onStateChange={(e) => {
                        console.log("onStateChange", e.data)
                    }}
                    onPlaybackRateChange={(e) => {
                        console.log("onPlaybackRateChange", e.data)
                    }}
                    onPlaybackQualityChange={(e) => console.log("onPlaybackQualityChange", e.data)}
                    onError={(e) => {
                        console.log("onError", e.data)
                    }}
                />
            </div>
            {debugMode && (
                <div className="absolute top-4 right-4 w-1/4">
                    <div className="border rounded-md bg-white opacity-55">
                        <div className="flex items-center justify-center space-x-2 py-2">
                            <p>Video timer: </p>
                            <p className="stopwatch-time">
                                {hours}:{minutes.toString().padStart(2, "0")}:
                                {seconds.toString().padStart(2, "0")}:
                                {milliseconds.toString().padStart(2, "0")}
                            </p>
                        </div>
                        <ul className="pt-4 divide-y">
                            {logs.map((log) => (
                                <li key={`log-${log.id}`} className="relative flex gap-x-4">
                                    <div className={'absolute left-0 top-0 flex w-6'}>
                                        <div className="w-px bg-gray-200"/>
                                    </div>
                                    <div
                                        className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                        <div
                                            className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>
                                    </div>
                                    <p className="flex-auto py-0.5 text-xs text-left leading-5 text-gray-500">
                                        {log.value}
                                    </p>
                                    <time dateTime={log.date.toLocaleDateString()}
                                          className="flex-none py-0.5 text-xs leading-5 text-gray-500 pr-4">
                                        {log.date.toString()}
                                    </time>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    )
}


function App() {
    return (
        <VideoTrackingPlayer debugMode={false}/>
    );
}

export default App;
