import React, {
    FunctionComponent,
    PropsWithChildren,
    RefObject,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import ReactPlayer from 'react-player'
import {useSearchParams} from "react-router-dom";
import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai'

type Log = { id: number, value: string, date: Date, message: string }

const ON_PLAYER_READY_EVENT: string = 'Ready to start'

const API_BASE_URL = "https://gpa-tracker-api.sysei.mx"
//const API_BASE_URL = "http://localhost:8000"

const logAtom = atom<Log[]>([])
const enableRecordEventsAtom = atom<boolean>(true)

const apiRecordEvent = async (
    eventType: number,
    videoId: string | null,
    userId: string | null,
    player?: RefObject<ReactPlayer>
) => {
    const internalPlayer = player?.current?.getInternalPlayer()

    return await fetch(`${API_BASE_URL}/v1/youtube-events/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "event_type": eventType,
            "video_id": videoId,
            "user_id": userId,
            "playback_quality": internalPlayer?.getPlaybackQuality(),
            "playback_rate": internalPlayer?.getPlaybackRate(),
            "error_code": null
        })
    })
}

const apiRecordTimeWatched = async (videoId: string | null, userId: string | null, hours: number, minutes: number, seconds: number, milliseconds: number) => {
    return await fetch(`${API_BASE_URL}/v1/youtube-time-watched/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "video_id": videoId,
            "user_id": userId,
            "hours": hours,
            "minutes": minutes,
            "seconds": seconds,
            "milliseconds": milliseconds,
        })
    })
}

// const apiRecordTimePenalized = async (videoId: string | null, userId: string | null, hours: number, minutes: number, seconds: number, milliseconds: number) => {
//     return await fetch(`${API_BASE_URL}/v1/youtube-time-penalized/`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//             "video_id": videoId,
//             "user_id": userId,
//             "hours": hours,
//             "minutes": minutes,
//             "seconds": seconds,
//             "milliseconds": milliseconds,
//         })
//     })
// }

const apiRecordTimeDuration = async (videoId: string | null) => {
    return await fetch(`${API_BASE_URL}/v1/youtube-duration/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "video_id": videoId,
        })
    })
}


const VideoTrackingPlayer: FunctionComponent<{
    debugMode?: boolean,
    videoId: string,
    userId: string,
    onReady: (player: RefObject<ReactPlayer>) => Promise<void>,
}> = ({debugMode, videoId, userId, onReady}) => {
    const [time, setTime] = useState(0)
    const [isRunning, setIsRunning] = useState(false)

    const [logs, setLogs] = useAtom(logAtom)
    const isEnabledRecordEvents = useAtomValue(enableRecordEventsAtom)

    const player = useRef<ReactPlayer>(null)

    const {hours, minutes, seconds, milliseconds} = useMemo(() => ({
        hours: Math.floor(time / 360000),
        minutes: Math.floor((time % 360000) / 6000),
        seconds: Math.floor((time % 6000) / 100),
        milliseconds: time % 100,
    }), [time])

    useEffect(() => {
        let intervalId: NodeJS.Timer | undefined = undefined
        if (isRunning) intervalId = setInterval(() => setTime(time + 1), 7.8)
        return () => clearInterval(intervalId)
    }, [isRunning, time]);

    const onReadyCallback = async () => {
        if (!isEnabledRecordEvents) {
            console.log("onReadyCallback: ignore record event")
            return
        }
        await apiRecordEvent(-2, videoId, userId, player)
        setLogs((prevState) => {
            if (!prevState.find(log => log.value === ON_PLAYER_READY_EVENT)) {
                return [...prevState, {
                    id: prevState.length + 1,
                    value: ON_PLAYER_READY_EVENT,
                    date: new Date(),
                    message: `Player: ${ON_PLAYER_READY_EVENT}`
                }]
            }
            return prevState
        })
        await onReady(player)
    }

    const onPlayCallback = async () => {
        if (!isEnabledRecordEvents) {
            console.log("onPlayCallback: ignore record event")
            return
        }
        await apiRecordEvent(1, videoId, userId, player)
        setLogs(prevState => {
            const value = 'Play'
            return [...prevState, {
                id: prevState.length + 1,
                value,
                date: new Date(),
                message: `Video player: ${value}`
            }]
        })
        setIsRunning(true)
    }

    const onEndCallback = async () => {
        if (!isEnabledRecordEvents) {
            console.log("onEndCallback: ignore record event")
            return
        }
        await apiRecordEvent(0, videoId, userId, player)
        setLogs(prevState => {
            const value = 'Completed'
            return [
                ...prevState,
                {
                    id: prevState.length + 1,
                    value,
                    date: new Date(),
                    message: `Video player: ${value}`
                }
            ]
        })
        setIsRunning(false)
    }

    const onPauseCallback = async () => {
        if (!isEnabledRecordEvents) {
            console.log("onPauseCallback: ignore record event")
            return
        }
        await apiRecordEvent(2, videoId, userId, player)
        setLogs(prevState => {
            const value = 'Pause'
            return [
                ...prevState,
                {
                    id: prevState.length + 1,
                    value,
                    date: new Date(),
                    message: `Video player: ${value}`
                }]
        })
        setIsRunning(false)
    }

    return (
        <>
            <div>
                <ReactPlayer
                    ref={player}
                    className='react-player'
                    url={`https://www.youtube.com/watch?v=${videoId}&fs=1`}
                    width="100vw"
                    height="100vh"
                    light={true}
                    config={{
                        youtube: {
                            playerVars: {
                                color: 'white',
                                autoplay: 1,
                                controls: 1,
                                playsinline: 1,
                                enablejsapi: 1,
                                rel: 0,
                                fs: 1,
                                iv_load_policy: 3,
                                modestbranding: 1,
                            },
                        }
                    }}
                    fallback={<>Error Loading player...</>}
                    onReady={onReadyCallback}
                    onPlay={onPlayCallback}
                    onEnded={onEndCallback}
                    onPause={onPauseCallback}
                    onProgress={async (state) => {
                        if (!isEnabledRecordEvents) {
                            console.log("onProgress: ignore record event", state)
                            return
                        }
                        await apiRecordTimeWatched(videoId, userId, hours, minutes, seconds, milliseconds)
                    }}
                />
            </div>
            {debugMode && (
                <div className="absolute top-4 right-4 w-1/4">
                    <div className="border rounded-md bg-white opacity-55">
                        <div className="flex items-center justify-center space-x-2 py-2">
                            <p>Video timer: </p>
                            <p className="stopwatch-time">
                                {hours}:{minutes.toString().padStart(2, "0")}:
                                {seconds.toString().padStart(2, "0")}:
                                {milliseconds.toString().padStart(2, "0")}
                            </p>
                        </div>
                        <ul className="pt-4 divide-y">
                            {logs.map((log) => (
                                <li key={`log-${log.id}`} className="relative flex gap-x-4">
                                    <div className={'absolute left-0 top-0 flex w-6'}>
                                        <div className="w-px bg-gray-200"/>
                                    </div>
                                    <div
                                        className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                        <div
                                            className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>
                                    </div>
                                    <p className="flex-auto py-0.5 text-xs text-left leading-5 text-gray-500">
                                        {log.message}
                                    </p>
                                    <time dateTime={log.date.toLocaleDateString()}
                                          className="flex-none py-0.5 text-xs leading-5 text-gray-500 pr-4">
                                        {log.date.toString()}
                                    </time>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    )
}

const ChangeBrowserTabWrapper: FunctionComponent<PropsWithChildren<{
    videoId: string,
    userId: string
}>> = ({children, videoId, userId}) => {
    const setLogs = useSetAtom(logAtom)
    const setEnableRecordEvents = useSetAtom(enableRecordEventsAtom)

    useEffect(() => {
        const handleVisibilityChange = async () => {
            const visibilityState = document.visibilityState
            const isVisible = visibilityState === 'visible'
            await apiRecordEvent(isVisible ? 6 : 5, videoId, userId)

            setEnableRecordEvents(isVisible)

            setLogs(prevState => {
                if (!prevState.find(log => log.value === visibilityState)) {
                    return ([
                        ...prevState,
                        {
                            id: prevState.length + 1,
                            value: visibilityState,
                            date: new Date(),
                            message: `Tab visibility: ${visibilityState}`
                        }
                    ])
                }
                return prevState
            })
        }

        document.addEventListener(
            "visibilitychange",
            handleVisibilityChange,
        );

        return () =>
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
    }, [setEnableRecordEvents, setLogs, userId, videoId]);

    return (<>{children}</>)
}


function App() {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);

    const videoId = useMemo(() => searchParams.get("id"), [searchParams])
    const userId = useMemo(() => searchParams.get("user-id"), [searchParams])

    useEffect(() => {
        if (isLoading && videoId && userId) setIsLoading(false)
    }, [isLoading, userId, videoId]);

    if (isLoading) return <>Loading screen...</>
    if (videoId === null) return <>Loading video data...</>
    if (userId === null) return <>Loading user data...</>

    return (
        <>
            {!isLoading && (
                <ChangeBrowserTabWrapper videoId={videoId} userId={userId}>
                    <VideoTrackingPlayer
                        videoId={videoId}
                        userId={userId}
                        debugMode={false}
                        onReady={async () => {
                            await apiRecordTimeDuration(videoId)
                        }}
                    />
                </ChangeBrowserTabWrapper>
            )}
        </>
    );
}

export default App;
